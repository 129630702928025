import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Navigation from './Navigation';
import PortfolioLink from './PortfolioLink';
import MediaQueries from '../utils/media-queries';
import Colours from '../utils/colours';

const HeaderContainer = styled.header`
  grid-area: Header;
  background: ${Colours.primary};
  padding: 0 5vw 0;
  ${(props) => (props.path === '/' ? 'margin: 0' : 'margin: 0 0 2rem')};
  @media only screen and (max-width: ${MediaQueries.insect}) {
    //padding: 0;
  }
  @media only screen and (max-width: ${MediaQueries.nano}) {
    //padding: 0;
  }
`;

const HeaderContent = styled.div`
  --LogoSize: 50px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: 80px;
  display: flex;
  justify-content: space-between;
  a {
    height: var(--LogoSize);
    align-self: center;
  }
  img {
    max-width: max-content;
    margin-bottom: 0;
    height: var(--LogoSize);
  }
  @media only screen and (max-width: ${MediaQueries.insect}) {
    height: 60px;
  }
`;

export default (props) => {
  return (
    <>
      <HeaderContainer path={props.path}>
        <HeaderContent>
          <Link to='/'>
            <img title='Josh Logo' src='/assets/joshlogo.png' alt='Josh Logo' />
          </Link>
          <Navigation path={props.path} />
        </HeaderContent>
      </HeaderContainer>
      {props.path === '/' ? <PortfolioLink></PortfolioLink> : null}
    </>
  );
};

import React from 'react';
import styled from 'styled-components';
import Colours from '../utils/colours';
import MediaQueries from '../utils/media-queries';

const PLinkContainer = styled.div`
  background-color: ${Colours.secondary2};
  grid-area: PLink;
  :hover {
    animation: colorchange 0.3s;
    animation-fill-mode: forwards;
  }
  @keyframes colorchange {
    0% {
    }
    100% {
      color: ${Colours.white};
      background-color: ${Colours.secondary1};
    }
`;

const PLinkContent = styled.span`
  font-family: 'Catamaran', sans-serif;
  color: inherit;
  margin: 0 auto;
  max-width: 1200px;
  display: block;
  
  }
  a {
    color: inherit;
    font-size: 0.9rem;
    font-weight: lighter;
    display: block;
    margin: 0;
    padding: 0.2rem 0;
    text-align: center;
    @media only screen and (max-width: ${MediaQueries.insect}) {
      font-size: 0.7rem;
    }
  }
`;

export default () => {
  return (
    <PLinkContainer>
      <PLinkContent>
        <a href='https://portfolio.joshlong.design'>
          ‹ My dedicated Product Design portfolio has moved here ›
        </a>
      </PLinkContent>
    </PLinkContainer>
  );
};

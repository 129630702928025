import React from 'react';
import { Link } from 'gatsby';
import Colours from '../utils/colours';
import styled from 'styled-components';

const Footer = styled.footer`
  font-family: 'Catamaran', -apple-system, BlinkMacSystemFont, Segoe UI, 'Roboto', Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  background-color: ${Colours.offWhite};
  grid-area: Footer;
  padding: 3vh 2vw 6vh;
  text-align: center;
  border-top: 3px solid ${Colours.secondary1};
`;

const BuiltWith = styled.p`
  position: relative;
  margin: 0;
  padding: 0;
  height: max-content;
  font-size: 0.9rem;
  a {
    text-decoration: none;
    margin: 0 0.2rem;
    :nth-of-type(1) {
      display: inline-block;
      width: 30px;
      height: 30px;
      text-indent: -9999px;
      background-size: cover;
      background-image: url(/assets/gatsbyicon.png);
    }
    :nth-of-type(2) {
      display: inline-block;
      width: 30px;
      height: 30px;
      text-indent: -9999px;
      background-size: cover;
      background-image: url(/assets/netlifyicon.png);
    }
  }
  span {
    display: inline-block;
    background: blue;
    height: 100%;
  }
`;

const Social = styled.section`
  --socialHeight: 25px;
  margin: 1rem 0 0.5rem;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  height: var(--socialHeight);
  img {
    position: relative;
    height: var(--socialHeight);
    width: var(--socialHeight);
    margin: 0 0.5rem 0 0;
    padding: 0;
  }
`;

const FooterLinks = styled.nav`
  ul {
    margin: 1rem auto 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    li {
      margin-left: 0rem;
      margin-right: 1rem;
      color: inherit;
      a {
        color: inherit;
        font-size: 1.1rem;
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
`;

const Copyright = styled.p`
  text-align: center;
  margin: 0;
  padding: 0;
`;

export default () => {
  return (
    <Footer>
      <FooterLinks>
        <ul>
          <li>
            <Link to='/blog'>Articles</Link>
          </li>
          <li>
            <Link to='/contact'>Get in touch</Link>
          </li>
        </ul>
      </FooterLinks>
      <BuiltWith>
        Built with
        <a rel='noopener noreferer' href='https://www.gatsbyjs.org'>
          {' '}
          GatsbyJS{' '}
        </a>
        and managed through
        <a rel='noopener noreferer' href='https://www.netlifycms.org/'>
          {' '}
          NetlifyCMS{' '}
        </a>
        .
      </BuiltWith>
      <Social>
        <a href='https://www.facebook.com/JLDesignBranding'>
          <img title='' src='/assets/facebook.png' alt='' />
        </a>
        <a href='https://www.linkedin.com/in/joshlongdesigner/'>
          <img title='' src='/assets/linkedin.png' alt='' />
        </a>
      </Social>
      <Copyright>&#169; {new Date().getFullYear()} Josh Long</Copyright>
    </Footer>
  );
};
